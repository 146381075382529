import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

// Default image:
// "deidre-schlabs-3JpjkYw3Xb4-unsplash_mod";


const BackgroundSection = ({ className, title }) => (
  <StaticQuery
    query={ graphql`
      query {
        desktop: file(relativePath: { eq: "deidre-schlabs-3JpjkYw3Xb4-unsplash_mod.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
    ` }
    render={data => {
      const image = data.desktop.childImageSharp.fluid;
      if (!image) {
        return null;
      }
      return (
        <BackgroundImage
          Tag="section"
          className={ className }
          fluid={ image }
          //backgroundColor={ `#040e18` }
          alt="Header background image"
          //title="Header image (photo by Deidre Schlabs on Unsplash)"
          title="Header image (photo by '' on Unsplash)"
        >
        </BackgroundImage>
      )
    }}
  />
);

const StyledBackgroundSection = styled( BackgroundSection )`
  width: 100%;
  height: 35vh;
  position: top;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

export default StyledBackgroundSection; 