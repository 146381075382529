/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent, { Cookies } from 'react-cookie-consent';
//import Header from "../Header/Header_special";
import Header from "../Header/Header_normal";
import Footer from "../Footer/Footer";

import './Cookies.css';

const Layout = ({ children, cssIdName, location }) => {
  const [showCookieBar, setCookieBar] = useState(true);
  const [helpCount, sethelpCount] = useState(0);
  const cookieName = "gatsby-gdpr-google-analytics";
  //console.log(Cookies.get(cookieName))
  if(Cookies.get(cookieName) && helpCount < 1){
    //console.log(Cookies.get(cookieName))
    setCookieBar(false);
    sethelpCount(1);
  }

  function handleClick() {
    if(Cookies.get(cookieName)) {
      Cookies.remove(cookieName)
    };
    setCookieBar(true);
  }
  const data = useStaticQuery(graphql`
    query SiteQuery {
      sitePage {
        path
      },
      site {
        siteMetadata {
          title
        }
      }
    }
  ` );
    
  return (
    <>
      <div className="site">
      <Header siteTitle={ data.site.siteMetadata.title } />
        <div className="site-content">
          <main>
          <div id={ cssIdName }>{ children }</div>
          </main>
        </div>
        <Footer siteTitle={ data.site.siteMetadata.title } onButtonClick={handleClick} />
      </div>
      ({showCookieBar && <CookieConsent
        //debug={true}
        cookieName={cookieName}
        sameSite="strict"
        //visible="show"
        onAccept={() => {
          //console.log('cookieName', cookieName)
          //Cookies.set(cookieName, "true" )
          //alert("Accept was triggered by clicking the Accept button");
          setCookieBar(false);
        }}
        onDecline={() => {
          //Cookies.set(cookieName, "false" )
          //setCookieBar(true);
          //alert("nay!");
        }}
        expires={150}
        location="bottom"
        buttonText="Alle cookies accepteren"
        declineButtonText="Alleen essentiële cookies"
        enableDeclineButton
        flipButtons={true}
        disableStyles={true}
        disableButtonStyles={true}
        overlay={true}
        overlayClasses="cookie-overlay"
        declineButtonClasses="cookie-buttons cookie-decline-button"
        buttonWrapperClasses="cookie-button-wrapper"
        buttonClasses="cookie-buttons cookie-accept-button"
        containerClasses="cookie-container"
        contentClasses="cookie-content"
      >
        <div>
        <div class="cookie-icon">
          <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-1.5-8c0 2 3 2 3 0V7c0-2-3-2-3 0v11z" fill="currentColor" fill-rule="evenodd"></path></svg>
        </div> 
        <div class="cookie-message">
          <div class="cookie-title">
            <p>Privacy verklaring</p>
          </div>
          <div class="cookie-text">
            <p>
            We gebruiken cookies en soortgelijke technologieën om uw voorkeuren te onthouden, zoals de inhoud van uw winkelmandje ("essentiële cookies") en om de effectiviteit van onze campagnes te meten en gedepersonaliseerde gegevens te analyseren voor het verbeteren van de prestaties van onze site ("niet-essentiële cookies"). Als u kiest voor "Alle cookies accepteren", stemt u in met het gebruik van niet-essentiële cookies. Klikt u op "Alleen essentiële cookies" dan zullen de niet-essentiele cookies uitgeschakeld worden. Essentiële cookies kunnen niet uitgeschakeld worden gezien deze nodig zijn om de site goed werkende te houden. U kunt de voorkeuren voor cookies op elk moment wijzigen door onderaan de site te klikken op "Cookies herzien". Leer meer in ons <a class="cookie-link" target="_blank" tabindex="0" rel="noreferrer noopener" href="/privacy-policy">Privacybeleid </a> en <a class="cookie-link" target="_blank" tabindex="0" rel="noreferrer noopener" href="/cookies">Cookiebeleid </a> .
            </p>
          </div>
        </div>
      </div>
    </CookieConsent>
    })
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  cssIdName: PropTypes.string,
}

Layout.defaultProps = {
   cssIdName: `default-layout`,
}

export default Layout;
