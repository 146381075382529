import React from 'react';
import { Link } from "gatsby";
import PropTypes from "prop-types";
import StyledBackgroundSection from "./Header-Background-Image.js";
import Navbar from "../Navbar/Navbar";

import "./Header.css";

const Header = ({ id, siteTitle }) => {
  return(
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    
    <h1 className="page-header" style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <StyledBackgroundSection title={siteTitle} />
      </Link>
    </h1>
    
    <Navbar title={siteTitle} />
  </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
