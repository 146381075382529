import React from "react";
import { InlineIcon } from '@iconify/react';
import truckDelivery from '@iconify/icons-tabler/truck-delivery';
import boxSeam from '@iconify/icons-bi/box-seam';
import houseIcon from '@iconify/icons-bi/house';


const Store = () => {
  const storeId = "35311023";
  const storeContainerID="storeContainer";

  return (
    <div id={ storeContainerID }>
      <div id="store-overlay" />
      <div id="store-topline">
        <div id={ `my-search-${ storeId }` } />
        <div id="delivery-info">
          <p id="free-delivery-info">
            <InlineIcon icon={ truckDelivery } height="2em" /> 
            <span className="icon-space">
              Gratis levering in Merelbeke en Oosterzele en vanaf €75 ook daarbuiten.
            </span>
          </p>
          <p id="pickup-info">
            <InlineIcon icon={ boxSeam } height="2em" /> 
            <span className="icon-space">
              Ophalen mogelijk dinsdag en donderdag (18:30 en 20:00)
            </span>
          </p>
          <p id="free-delivery-info">
            <InlineIcon icon={ houseIcon } height="2em" /> 
            <span className="icon-space">
              Gratis ophaalmoment op dinsdag (Merelbeke) en donderdag (Oosterzele) tussen 18u en 20u
            </span>
          </p>
        </div>
      </div>
      <div id={ `my-categories-${ storeId }` } />
      <div id={ `my-store-${ storeId }` } />
    </div>
  );
};

export default Store;
