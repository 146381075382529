// Navbar.js
import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import NavbarLinks from "./NavbarLinks";
import Logo from "./Navbar-Logo";
import { useNavbarContext } from './NavbarProvider';
import { CartWidget } from "../Store/StoreConfig";
import RouteChange from "../RouteChange/RouteChange";


const Navigation = styled.nav`
  height: 10vh;
  display: flex;
  background-color: #fff;
  top: 0;
  left: 0,
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 200;
  align-self: center;
  width: 100%;
  position: fixed;
  transform: ${ props => ( props.isVisible ? "translateY(0)" : "translateY(-100%)" ) };
  transition: transform 0.5s ease-in-out;

  @media ( max-width: 736px ) and ( max-height: 280px ) and ( orientation: landscape ) {
    height: 12vh;
  }

  @media ( max-width: 768px ) {
    left: 0;
    right: 0;
  }

  @media ( max-width: 768px ) and ( orientation: portrait ) {
    height: 8vh;
  }
`;

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  width: 45%;

  @media ( max-width: 768px ) {
    display: flex;
    flex: 1 1 90px;
  }
`;

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media ( max-width: 768px ) {
    flex-direction: column;
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 10vh;
    margin-top: -1px;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${ props => ( props.open ? "-100%" : "0" ) };
  }
  @media ( max-width: 736px ) and ( orientation: landscape ) {
    top: 10vh;
  }
  @media ( max-width: 736px ) and ( max-height: 280px ) and ( orientation: landscape ) {
    top: 12vh;
  }
`;

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  z-index: 2;
  transform: ${ props => ( props.open ? "rotate(-45deg)" : "inherit" ) };

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
    text-decoration: underline;
  }

  ::before {
    transform: ${ props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)" };
    top: -10px;
  }

  ::after {
    opacity: ${ props => ( props.open ? "0" : "1" ) };
    transform: ${ props => ( props.open ? "rotate(90deg) " : "rotate(0deg)" ) };
    top: 10px;
    text-decoration: underline;
  }
`;
const Navbar = ( { id, title } ) => {
  const [ navbarOpen, setNavbarOpen ] = useState( false );
  const { isVisible } = useNavbarContext();

  function toggleCart() {
    navigate( "/" );
    sessionStorage.setItem( 'storeCartClicked', "true" );
    RouteChange( { location: window.location, prevLocation: document.referrer } );
  }
  

  return (
    <Navigation isVisible={ isVisible }> 
      <div id="navLogo"><Logo /></div>
      <h1 id="navbarTitle">
        <Link to="/" style={{ textDecoration: 'none' }}>
          { title }
        </Link>
      </h1>
      <Toggle
        navbarOpen={ navbarOpen }
        onClick={ () => setNavbarOpen( ! navbarOpen ) }
      >
        { navbarOpen ? <Hamburger open /> : <Hamburger /> }
      </Toggle>
      { navbarOpen ? (
        <Navbox
          navbarOpen={ navbarOpen }
          onClick={ () => setNavbarOpen( ! navbarOpen ) }
        >
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )} 
      <div className="navbar-cart-widget">
        <div 
          id="cart-overlay" 
          onClick={ toggleCart } 
          onKeyDown={ toggleCart }
          role = "button"
          aria-label="Ga naar het winkelmandje"
          tabIndex={ 0 }
        />
        <div className="navbar-cart-widget2">
          <CartWidget />
        </div>
      </div>
    </Navigation>
  );
};

export default Navbar;