module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-L7VX7X8Y2L","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"pageTransitionDelay":300,"respectDNT":true},"googleTagManager":{"trackingId":"G-L7VX7X8Y2L","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","staging","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dezoetefee.be","noQueryString":true,"noHash":false,"noTrailingSlash":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"de-zoete-fee-e-store","short_name":"de-zoete-fee","start_url":"/","background_color":"rgb(255,156,161)","theme_color":"rgb(255,156,161)","display":"minimal-ui","icon":"src/images/logo2-dzf.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"30596c9c8991048935fd5b2b476daa60"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/srasmond/Library/Mobile Documents/com~apple~CloudDocs/fibeRSwITch/Projecten/dezoetefee-v2/e-commerce/wrapPageElement.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
