import React from "react";
import { isBrowser } from '../../utils/utils';

const StoreConfig = (props) => {
  //const storeId = "35311023";
  //const urlRef = `https://app.ecwid.com/script.js?${storeId}`;

  //useEffect( () => {
    if ( isBrowser() ) {
      try {
        window.scrollTo( 0, 0 );
        window.localStorage.setItem("show_ecwid_logs","true");
        window.ecwid_script_defer = false;
        window.ec = window.ec || Object();
        window.ec.storefront = window.ec.storefront || Object();
        // window.ec.config.store_main_page_url = "http://www.dezoetefee.be/winkel";
        window.ec.enable_catalog_on_one_page = true;
        window.ecwid_custom_url = process.env.GATSBY_SITE_URL_STORE;
        //window.ec.config.custom_redirect_after_purchase =  "http://localhost:8000/allergenen";
        //window.ec.config.scroll_indent = 100; 
        try {
          window.ec.config.navigation_scrolling = "DISABLED";
          //window.ec.config.navigation_scrolling = "CUSTOM";
          //console.log( "trying navigation scrolling" );
          /*window.ec.config.custom_scroller = function() {
            window.scrollTo( 0, 0 );
          };*/
        }
        catch ( err ) {
          console.log( "error with nav scrolling", err );
        }
        window._xnext_initialization_scripts = [
          {
            widgetType: 'ProductBrowser',
            id: 'my-store-35311023',
            arg: [ "id=productBrowser", "views=grid(20,3)" ]  
          },
          {
            widgetType: 'CategoriesV2',
            id: 'my-categories-35311023',
            arg: [ "id=categoriesV2" ]
          },
          {
            widgetType: 'SearchWidget',
            id: 'my-search-35311023',
            arg: [ "id=searchWidget" ]
          },
          {
            widgetType: 'Minicart',
            id: 'my-minicart-35311023',
            class: 'ec-cart-widget',
            style: '',
            arg: [ "id=miniCart", "class=ec-cart-widget", "data-layout=SMALL_ICON_COUNTER" ]
          }
        ];
        window.scrollTo( 0, 0 );
      }
      catch ( err ) {
        console.log( "error with loading store", err );
      }
    }
    return null;
  //});
};

export const CartWidget = () => (
  <div className="ec-cart-widget">
    <div id="my-minicart-35311023" />
  </div>
);

export default StoreConfig;
