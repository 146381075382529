import PropTypes from "prop-types";
import React from "react";
import {Link} from "gatsby";
import PaymentIcons from "./PaymentIcons";

import "./Footer.css";

const Footer = ( { siteTitle, onButtonClick } ) => (
    <footer>
        <div id="info-links">
          <Link to="/allergenen">Allergenen</Link>
          -
          <Link to="/algemene-voorwaarden">Algemene voorwaarden</Link>
          -
          <Link to="/privacy-policy">Privacy Policy</Link>
          -
          <Link to="/cookies">Cookies</Link>
          -
          <Link to="/verzending-en-betaling">Verzendings- en betalingsinfo</Link>
        </div>
        <div id="footer-icons">
          <PaymentIcons />
        </div>
        <div id="cookie-consent">
          <button onClick={onButtonClick} >Cookies herzien</button>
        </div>
        <div id="siteAndAuthor">
          <p>
          © { siteTitle } vof - { new Date().getFullYear() }
          {` `} - {` `}
          <span id="site-map">
            <Link to="/sitemap.xml">sitemap</Link>
          </span>
          </p>
          {` `}
          <p id="footerInfo" >
              Ontwikkeld door {` `}
              <a 
                href="https://www.fiberswitch.be" 
                target="_blank"
                rel="noreferrer noopener"
              >
                  fibeRSwITch
              </a>.
          </p>
        </div>
    </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;