// Logo.js
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;
  margin-right:1em;
  padding: 0.1em;
  object-fit: contain;

  @media ( max-width: 768px ) and ( orientation: landscape ) {
    flex: 0 1 25px;
    
  }
`
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "logo-dzf" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 80, pngQuality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="zoomout frame">
      <LogoWrap as={ Link } to="/">
        <Img className="dzf-logo-nav" fluid={ data.file.childImageSharp.fluid } alt="logo" />
      </LogoWrap>
    </div>
  );
};

export default Logo;