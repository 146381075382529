import React, { createContext, useMemo, useContext, useEffect } from 'react';
import { ScrollDirection, useScrollContext } from '../Providers/ScrollProvider';
import { useVisibility } from '../../hooks/useVisibility';

const TOP_START = 10;
export const NavbarContext = createContext({  
  isVisible: true,
});

export const NavbarProvider = ( { children } ) => {  
    const { isVisible, show, hide } = useVisibility( true );
    const { scrollTop, direction } = useScrollContext();
    
    useEffect( () => {    
      const shouldShow = scrollTop <= TOP_START || direction !== ScrollDirection.Down;
      const shouldHide = (
        scrollTop > TOP_START &&
        direction === ScrollDirection.Down
      );
      if ( shouldShow ) {
        show();
      } 
      else if ( shouldHide ) {
        hide();
      }
    }, [ scrollTop, direction, hide, show ]);
    
    const value = useMemo( () => ({    
      isVisible,
    }), [    
      isVisible,
    ]);
  
    return (    
      <NavbarContext.Provider value={ value }>      
        { children }    
      </NavbarContext.Provider>  
    );
  };

  export const useNavbarContext = () => useContext( NavbarContext );