import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { isBrowser } from '../../utils/utils';

const RouteChange = ( props ) => {
    const location = props.location
    const prevLocation = props.prevLocation
    //const storeCartClicked = props.storeCartClicked
    const storeCartClicked = sessionStorage.getItem( 'storeCartClicked' );

    console.log("theloc", location )
    //console.log("prevLocation", prevLocation )
    //console.log("storeCartClicked", storeCartClicked )

    if ( location.pathname === "/" ) {
        // Checks in case we where already on the site
        if ( prevLocation ) {
            if ( prevLocation.pathname === "/"  && location.hash !== "" ) {
                // Don't do anything when we are navigating in the store
            }
            else if ( prevLocation.hash === "" ) {
                document.getElementById( 'storeContainer' ).style.visibility = 'visible';
                document.getElementById( 'storeContainer' ).style.position = 'relative';
                
                if ( ! ( prevLocation.pathname === "/allergenen" && location.hash !== "" ) && location.hash !== "#!/~/cart" ) {
                    window.location.href = "/#!/c/";
                    window.location.href = "/#!/c/0";
                
                }
            }
            else if ( prevLocation.hash !== "" ) {     
                window.location.href = "/#!/c/";
                window.location.href = "/#!/c/0";
            }
        }
        // Checks in case we are just entering the site
        else {
            if ( location.hash !== "" && storeCartClicked === "false" ) {
                // We probably entered the store with a product URL
                // We should not do anything in this case
            }
            else {
                window.location.href = "/#!/c/";
                window.location.href = "/#!/c/0";
            }
        }

        // Special check in case we clicked the cart
        if ( storeCartClicked === "true" ) {
            navigate( "/#!/~/cart" );
            sessionStorage.setItem( 'storeCartClicked', "false" );
        }
    }
    else {
        document.getElementById( 'storeContainer' ).style.visibility = 'hidden';
        document.getElementById( 'storeContainer' ).style.position = 'fixed';

        // Handle display for a button in the "allergenen" page
        // depending on how we access the page
        if ( ! prevLocation && location.pathname === "/allergenen/" ) {
            document.getElementById( 'al-button' ).style.display = 'none';
        }
        else if ( prevLocation && location.pathname === "/allergenen/" ) {
            document.getElementById( 'al-button' ).style.display = 'block';
        }  
    }
};

if ( isBrowser() ) {
    RouteChange.propTypes = {
        location: PropTypes.object,
        prevLocation: PropTypes.object,
        //storeCartClicked: PropTypes.bool,
    }
    
    RouteChange.defaultProps = {
        location: window.location,
        prevLocation: document.referrer,
        //storeCartClicked: false,
    }
}
export default RouteChange;

