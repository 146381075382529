/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/components/Layout/Layout.css";
import "./src/components/Header/Header.css";
//import "./src/components/Loader/Loader.css";
import "./src/components/Store/Store.css";
import "./src/components/Navbar/Navbar.css";
import RouteChange from "./src/components/RouteChange/RouteChange";


/*
function matchRuleShort( str, rule ) {
  var escapeRegex = ( str ) => str.replace( /([.*+?^=!:${}()|[\]/\\])/g, "\\$1" );
  return new RegExp( "^" + rule.split( "*" ).map( escapeRegex ).join( ".*" ) + "$" ).test( str );
}
*/

// Event, right before leaving the app
/*
window.onbeforeunload = () => {
  sessionStorage.setItem("leaving site", "true")
}
*/

//const transitionDelay = 500;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  //const currentPosition = getSavedScrollPosition(location)
  //const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  //window.scrollTo(...(currentPosition || [0, 0]))
  /*
  window.scrollTo(0,0);
  console.log("now?")
  return true;
*/

  //if (location.action === "PUSH") {
  //  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  //} 
  //else {
    const savedPosition = getSavedScrollPosition(location);
    console.log("savedPosition", savedPosition);
    //window.setTimeout(
      //() => window.scrollTo(...(savedPosition || [0, 0])),
      window.scrollTo(([0, 0]))
      //transitionDelay
    //);
  //}
  return false;
}


export const onPreRouteUpdate = ( { location, prevLocation } ) => {
  //console.log("Gatsby started to change location to", location.pathname)
  //console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
};

export const onRouteUpdate = ( { location, prevLocation } ) => {
  if ( location && location.state ) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
 
  RouteChange( { location, prevLocation } )

    /*
    const in5Minutes = 1/288;
    if ( location.hash === "#!/~/checkoutPD" ) {
      Cookies.set('CheckingOut', 'true', {
        expires: in5Minutes
      });
    }
  */

  //window.scrollTo( 0,0 );
    
};

export const onRouteUpdateDelayed = () => {
  //console.log( "We can show a loading indicator now" );
};

export const onInitialClientRender = () => {
  /*setTimeout( function() {
    document.getElementById( 'loaderDiv' ).style.display = "none";
  }, 3300 );*/

  window.scrollTo( 0, 0 );

};
  
export const onClientEntry = () => {
  sessionStorage.setItem("storeCartClicked", "false");
};