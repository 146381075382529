import React from "react";
import Layout from "./src/components/Layout/Layout";
//import Loader from "./src/components/Loader/Loader";
import StoreConfig from "./src/components/Store/StoreConfig";
import Store from "./src/components/Store/Store";


// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location

const wrapPageElement = ( { children, props } ) => {

  /*
    const in5Minutes = 1/288;
    if ( location.hash === "#!/~/checkoutPD" ) {
      Cookies.set('CheckingOut', 'true', {
        expires: in5Minutes
      });
    }
    */
  return (
    <>
    <div id="ecwid_product_browser_scroller" />
    <div id="mainContainer">
      <div id="fullPageTemplate">
        <Layout {...props}>
          {children}
          <Store />
        </Layout>
      </div>
    </div>
    <StoreConfig />
    </>
  );
};

export default wrapPageElement