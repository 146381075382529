// NavbarLinks.js
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.1s ease-in;
  }

  :hover {
    color: rgba(255,156,161,1);
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const NavbarLinks = () => {
  return (
    <div className="nav-items">
      <NavItem to="/">Winkel</NavItem>
      <NavItem to="/over">Over</NavItem>
      <NavItem to="/contact">Contact</NavItem>
    </div>
  );
};

export default NavbarLinks;