import React, { createContext, useContext, useMemo } from 'react';
import { useScroll } from '../../hooks/useScroll';

const WAIT = 150;
export const ScrollDirection = {
  Down: 'down',
  None: 'none',
  Up: 'up',
};

export const ScrollContext = createContext({
  scrollTop: 0,
  previousScrollTop: 0,
  time: WAIT,
  amountScrolled: 0,
  direction: ScrollDirection.None,
  velocity: 0,
});

export const ScrollProvider = ( { children } ) => {
  const { scrollTop, previousScrollTop, time } = useScroll( WAIT );
  const amountScrolled = useMemo( () => (
    scrollTop - previousScrollTop
  ), [ scrollTop, previousScrollTop ] );

  const direction = useMemo( () => {
    if ( amountScrolled < 0 ) {
      return ScrollDirection.Up
    } else if ( amountScrolled > 0 ) {
      return ScrollDirection.Down
    }
    return ScrollDirection.None
  }, [ amountScrolled ]);

  const velocity = useMemo( () => (
    Math.abs( amountScrolled / time )
  ), [ amountScrolled, time ] );

  const value = useMemo( () => ({
    scrollTop,
    previousScrollTop,
    time,
    amountScrolled,
    direction,
    velocity,
  }), [
    scrollTop,
    previousScrollTop,
    time,
    amountScrolled,
    direction,
    velocity,
  ]);

  return (
    <ScrollContext.Provider value={ value }>
      { children }
    </ScrollContext.Provider>
  );
};

export const useScrollContext = () => useContext( ScrollContext );